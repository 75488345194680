/* Carousels */
.search__card {
  position: relative;
  background-image: url(../../../assets/img/background/my-card-bg.png);
  padding: 60px 0 150px;
  background-position-y: 45px;
  background-size: cover;
}
.search__card::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  height: 47px;
  width: 100%;
  background: #fff;
}
/* .search__card::after {
  content: '';
  position: absolute;
  top: 9%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../../../assets/img/background/bg-top-search.png);
  background-size: 100%;
  background-repeat: no-repeat;
} */
.search__card .filter-card {
  padding: 20% 14px 0;
}
.search__card .result {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #f4f8f9;
  padding: 10px 14px;
}
.search__card .list-card-result {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3px 14px 100px 14px;
}
.search__card .card-result {
  background: #fcdaae;
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 10px;
  border: 4px solid #fee7c8;
}
.search__card .card-result .infor-card {
  padding: 3px 10px;
}
.search__card .card-result .infor-card p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 7px;
}
.search__card .card-result .infor-card .rename {
  margin-bottom: 10px;
}
.search__card .card-result .infor-card .transfer button {
  background-image: url(../../../assets/img/button-white.png);
  color: #000;
}
.rename-box .modal-body {
  background-image: url(../../../assets/img/background/homepage_brand_border.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  padding: 25px;
}
.rename-box .modal-content {
  border: none;
  background: none;
}
.rename-box .modal-header {
  width: 98%;
  margin: auto auto -7px auto;
  border: none;
}
.rename-box img {
  width: 100%;
}
.rename-box .form-group {
  padding: 0 15px;
}
.rename-box .group-info .information {
  padding: 0 15px;
}

.rename-box span.error,
.rename-box .group-info .form-rename input,
.rename-box .form-rename label,
.rename-box .group-info p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.rename-box .bg-red,
.rename-box .bg-black-name {
  position: relative;
  height: 37px;
  line-height: 37px;
  margin-bottom: 15px;
}
.rename-box .bg-red input,
.rename-box .bg-black-name input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 3;
  width: 99%;
  height: 100%;
  transform: translateX(-50%);
}
.rename-box .bg-red input:focus,
.rename-box .bg-black-name input:focus {
  outline: none;
}
.rename-box .bg-red::before {
  content: '';
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff2e00;
  height: 42px;
  width: 99%;
  z-index: 2;
  position: absolute;
}
.rename-box .bg-red::after {
  content: '';
  top: 50%;
  left: -1px;
  transform: translateY(-50%);
  background: #ff2e00;
  height: 94%;
  width: 100.5%;
  z-index: 2;
  position: absolute;
}
.success-text {
  color: #03ac0c;
  font-size: 25px;
  text-align: center;
}
.rename-box .bg-black-name::before {
  content: '';
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  height: 42px;
  width: 99%;
  z-index: 2;
  position: absolute;
}
.rename-box .bg-black-name::after {
  content: '';
  top: 50%;
  left: -1px;
  transform: translateY(-50%);
  background: #000;
  height: 94%;
  width: 100.5%;
  z-index: 2;
  position: absolute;
}
.rename-box span.error {
  color: #ff2e00;
  margin-bottom: 10px;
  display: inline-block;
  position: absolute;
  bottom: 22px;
  left: 28px;
}
.rename-box .group-info {
  background: #fee7c8;
  padding: 5px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .search__card .filter-card {
    padding: 12px 8px 0;
    margin: 30px 0 5px;
  }
  .search__card .card-result {
    /* width: 32%; */
    flex-direction: row;
  }
  .rename-box {
    /* top: 20% !important; */
  }
}
@media (min-width: 1024px) {
  .rename-box .modal-body {
    padding: 30px;
  }
  .rename-box .group-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
  }
  .rename-box img {
    width: auto;
  }
  .rename-box .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
  .rename-box .form-group .left {
    display: flex;
    flex-direction: column;
    width: 53%;
    margin-right: 10px;
  }
  .rename-box .form-group .right {
    margin-top: 22px;
  }

  .rename-box .form-group .right button:disabled {
    opacity: 0.5;
  }
  .rename-box span.error {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  .search__card .card-result {
    width: 32%;
    padding: 20px;
  }

  .search__card .card-result img{
    width: 50%;
    object-fit: contain;
  }

  .search__card .card-result .infor-card .transfer button,
  .search__card .card-result .infor-card .rename button {
    width: 100%;
  }
  .rename-box .form-group .left {
    width: 60%;
  }
}
@media screen and (max-width: 767px) and (min-width: 600px) {
}

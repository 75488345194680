.navDropdown {
  margin-right: 0;
}
.navLink {
  color: white;
  font-size: 24px;
}
.navLink:hover {
  color: white;
}

.btnMyAcount {
  background-color: #FFB100 !important;
  border-radius: unset !important;
  width: 110.5px;
}
.btnHeco {
  background-color: #43A8DE !important;
  border-radius: unset !important;
  width: 110.5px;
}
.nav_navDropdown__2WiAX {
  margin-left: 0;
}
.nav-item {
  margin-left: 0 !important;
  z-index: 999 !important;
}
.dropdown {
  z-index: 999 !important;
}
.navbar-toggler {
  background-color: #fff;
}
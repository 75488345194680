.carousel-control-prev {
  left: -110px !important;
}
.carousel-control-next {
  right: -110px !important;
}

.carousel-control-prev-icon {
  width: 40px !important;
  height: 40px !important;
}

.carousel-control-next-icon {
  width: 40px !important;
  height: 40px !important;
}

.carousel {
  width: 86%;
}

.carousel-item img {
  width: 100%;
}
/* Card List */
#footer .themed-container {
  padding-top: 15px;
}
.card__list img {
  width: 100%;
  height: auto;
}
.card__list {
  /* padding: 100px 0; */
  /* background-image: url(../../../assets/img/background/bg_card.png);
  background-size: cover; */
  margin: 30px 0;
}
.card__list .card__list--item {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
}
.card__list--item-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.card__list .card__list--item-parent {
  width: 50%;
}
.filter-card {
  position: relative;
  z-index: 999;
  padding: 0 8px;
}
.filter-card select,
.slide-card .nav-tabs .nav-link,
.card__list .card__list--item span {
  color: #f7f7f7;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
}
/* End Card List */
/* Slide Card */
.slide-card .nav-tabs {
  margin-top: 10px;
  border-bottom: none;
  padding: 0 10px;
}
.slide-card .nav-tabs .nav-link {
  font-size: 13px;
  color: #333333;
}
.slide-card .nav-tabs .nav-link:last-child {
  margin-right: 0;
}
.slide-card .nav-tabs .nav-link.active,
.slide-card .nav-tabs .nav-item.show .nav-link {
  color: #f7f7f7;
  background-color: #000;
  position: relative;
  border: none;
}
.slide-card .nav-tabs .nav-link {
  position: relative;
  background-color: #fff;
  position: relative;
  border: none;
  border-radius: 0;
  margin-right: 20px;
  text-align: center;
  /* width: 152px; */
  height: 44px;
  line-height: 44px;
  padding: 0;
  flex: 1 0 0;
  background-size: 100% 100% !important;
}
.slide-card .nav-tabs .nav-link.active {
  background: url(../../../assets/img/buttons/btn-black.png);
  background-repeat: no-repeat;
}
.slide-card .nav-tabs .nav-link {
  background: url(../../../assets/img/buttons/btn-white.png);
  background-repeat: no-repeat;
}
/* .slide-card .nav-tabs .nav-link.active::before,
.slide-card .nav-tabs .nav-link::before {
  content: '';
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  height: 37px;
  width: 100%;
  z-index: -1;
  position: absolute;
}
.slide-card .nav-tabs .nav-link.active::after,
.slide-card .nav-tabs .nav-link::after {
  content: '';
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #000;
  height: 100%;
  width: 107%;
  z-index: -1;
  position: absolute;
} */
/* End Slide Card */
/* .slide-card .nav-tabs .nav-link.active {
  width: 21%;
} */
/* Filter Card */
.filter-card .left {
  text-align: center;
  padding: 30px 0 15px;
}
.filter-card select {
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(../../../assets/img/buttons/btn-white.png);
  background-repeat: no-repeat;
  font-size: 14px;
  color: #333;
  text-align-last: center;
  width: 152px;
  height: 43px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.filter-card select::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000000;
  width: 90%;
  height: 50px;
}
.filter-card .input-search {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  margin: 30px 0 10px;
}
.filter-card .input-search input {
  padding-left: 10px;
}
.filter-card .input-search input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #aaa9ae;
}
.filter-card .input-search .bg-black {
  position: relative;
  height: 34px;
  line-height: 34px;
  padding: 0 0 0 3px;
}
.filter-card .input-search .bg-black input {
  background-image: url(../../../assets/img/buttons/btn-input.png);
  background-repeat: no-repeat;
  border: none;
  height: 43px;
  background-color: transparent;
}

.filter-card .input-search button {
  position: relative;
}
.filter-card .input-search button {
  height: 43px;
}
.filter-card .input-search button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  border-left: 4px solid #000;
}
.filter-card .left .bg-game {
  position: relative;
  text-align: left;
  display: inline-block;
  padding: 0 3px;
}
.filter-card .left .bg-game::before {
  content: '';
  top: 18px;
  right: 3px;
  transform: translateX(-50%);
  z-index: 5;
  position: absolute;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACHSURBVHgBXU6xDcMwDJPVHJDNha8o7EPaE3JKP2n7gftB1gw2PGbM5jUX2JEBJVBCQKBIiISUc+4FAE+afwjBA0P6WGt90DIwH5D+TWu9KqUmMnpjTJ9znrnlTvMrpYxdIpBI1to3p/3eGmP8Nsa9nhKef/lIfTrixobhoqGTz7Y0Ii7ESfobgodGyEQN9HIAAAAASUVORK5CYII=);
}
.filter-card .right {
  text-align: center;
}
/* End Filter Card */
/* Banner Card */
.banner__card {
  margin-top: 30px;
  position: relative;
}
.banner__card img {
  width: 100%;
  min-height: 150px;
}
.banner__card button {
  position: absolute;
  bottom: 10%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}
/* End Banner Card */
#uncontrolled-tab-example-tabpane-card-library {
  height: 100%;
  position: relative;
}

#uncontrolled-tab-example-tabpane-card-library::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(../../../assets/img/background/bg_card.png);
  background-size: cover;
}
.modal-header {
  background-color: #020c16;
  color: #ffffff;
  border-radius: 0 !important;
  padding: 0.3rem 1rem !important;
}
.modal-header .modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.modal-header .close {
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  font-size: 30px;
}
.modal-body {
  background: #ffca82;
  border-bottom: 1px solid #ff2e00;
  border-right: 1px solid #ff2e00;
  border-left: 1px solid #fee7c8;
  border-top: 1px solid #fee7c8;
}
.list-card-modal .group-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list-card-modal .group-info .information {
  background: #fee7c8;
  /* transform: translateX(-14px);
  padding: 0.3rem; */
  padding: 1rem;
  width: 100%;
}
.list-card-modal .group-info .information p {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}
.list-card-modal {
  /* top: 25% !important;
  transform: translateY(-5%) !important; */
}
@media (min-width: 768px) {
  .filter-card {
    margin: 30px 0;
  }
  .slide-card .nav-tabs .nav-link {
    width: 152px;
    flex: unset;
  }
  .groups-filterr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-card .left {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  .filter-card .input-search {
    margin: 0;
  }
  .filter-card .left .bg-game {
    margin-right: 15px;
  }
  .card__list {
    margin: 0;
  }
  .card__list {
    justify-content: flex-start;
  }
  .card__list .card__list--item-parent {
    width: 20%;
  }
  .list-card-modal .group-info {
    flex-direction: row;
  }
  .list-card-modal .group-info .information {
    transform: translateX(-16px);
    padding: 2rem;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .list-card-modal .modal-body {
    padding: 3rem;
  }
}
@media (min-width: 1024px) {
  .slide-card .nav-tabs .nav-link {
    /* width: 13%;
    max-width: 13%; */
  }
  .list-card-modal .group-info .information {
    padding: 3rem;
  }
  .list-card-modal .group-info .information p {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  #uncontrolled-tab-example-tabpane-card-library::before {
    top: 20%;
  }
  .search__card .search__card--container,
  .slide-card .nav-tabs,
  .card__list,
  .filter-card,
  .banner__card {
    max-width: 1366px;
    width: 100%;
    margin: 30px auto;
    margin-bottom: 0;
  }
  .slide-card .nav-tabs,
  .filter-card,
  .banner__card {
    padding: 0 14px;
  }
  .slide-card .nav-tabs .nav-link {
    /* width: 11%;
    max-width: 11%; */
  }
  /* .list-card-modal {
    top: 25% !important;
    transform: translateY(-5%) !important;
  } */
  .list-card-modal .group-info {
    height: 100%;
  }
}
@media screen and (max-width: 767px) and (min-width: 600px) {
  .slide-card .nav-tabs .nav-link {
    /* width: 15%;
    max-width: 15%; */
  }
}

.card__box {
  padding: 15px;
  transform: translateY(-88px);
}
.card__box .card-box-3,
.card__box .card-box-2,
.card__box .card-box-1 {
  background: #f7c785;
  border: 4px solid #ffffff;
  padding: 30px 45px;
  margin-bottom: 10px;
}
.card__box .card-box-3 {
  text-align: left;
}
.card__box .card-box-3 p,
.card__box .card-box-2 p,
.card__box .card-box-1 p {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.card__box .card-box-1 p {
  font-size: 30px;
}
@media (min-width: 768px) {
  .card__box {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card__box .card-box-3,
  .card__box .card-box-2,
  .card__box .card-box-1 {
    width: 30%;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .card__box .card-box-2 {
    align-items: flex-start !important;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

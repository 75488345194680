/* Carousels */
.slick-slide.slick-active:nth-child(1) {
    width: 50px;
    height: 50px;
}

.card__carousels {
    position: relative;
    background-image: url(../../../assets/img/background/home_page_card_section2.png);
    padding: 60px 0 0px;
    background-size: cover;
}
.card__carousels.custom-bottom {
    padding: 0;
    background-position: bottom;
}

.card__carousels .carousels.move-up {
    position: relative;
    bottom: 200px;
}

.slick-slide .item p {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 12px;
    margin: 0 auto;
}

.slick-slide .item {
    width: 75% !important;
    cursor: pointer;

    position: relative;
}

.slick-slide .item span {
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #f7f7f7;
}

.slick-slide .item h3,
.slick-slide .item h2 {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #f4f8f9;
}

.slick-slide .item h3 {
    font-size: 20px;
}

.slick-slide .item img {
    width: 100%;
    outline: none !important;
}

.slick-slide .item img:focus {
    outline: none !important;
}

.slick-arrow.slick-prev {
    left: 0;
    z-index: 999;
    width: 44px;
    height: 80px;
}

.slick-arrow.slick-next {
    right: 0;
    z-index: 999;
    width: 44px;
    height: 80px;
}

.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
    content: none;
}

.btn-buy {
    margin-top: 30px;
}

.btn-buy button {
    text-transform: uppercase;
    font-size: 24px;
    height: 60px;
}

.burn {
    position: relative;
    margin: 80px auto 0;
    overflow: hidden;
    width: 70%;
    bottom: 156px;
}

.burn button {
    text-transform: uppercase;
    font-size: 24px;
    height: 60px;
}

.burn img {
    width: 100%;
    min-height: 270px;
}

.burn img.fire {
    min-height: unset;
    width: 98%;
}

.burn p {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    width: 70%;
    color: #f4f8f9;
}

.burn button {
    position: absolute;
    bottom: 28%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.burn .fire {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
}

.burn-success .modal-dialog,
.spinner-burn .modal-dialog {
    background: none;
    border: none;
}

.burn-success .modal-header,
.burn-success .modal-content,
.spinner-burn .modal-header,
.spinner-burn .modal-content {
    background-color: unset;
    background-clip: unset;
    border: none;
    border-radius: 0;
    padding: 0 !important;
}

.burn-success .modal-header,
.spinner-burn .modal-header {
    position: relative;
}

.burn-success .modal-body,
.spinner-burn .modal-body {
    background: none;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.burn-success .modal-body {
    height: 55%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-image: url(../../../assets/img/background/bg-burnin-success.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 67%;
}

.burn-success img,
.spinner-burn img {
    width: 100%;
    height: auto;
}

.burn-success .modal-body .pending span,
.burn-success .modal-body .pending p,
.spinner-burn .modal-body .pending span,
.spinner-burn .modal-body .pending p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #020c16;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.burn-success .modal-body .pending p,
.spinner-burn .modal-body .pending p {
    top: 20%;
}

.burn-success .modal-body .pending span,
.spinner-burn .modal-body .pending span {
    font-size: 13px;
    color: #ffffff;
    bottom: 20%;
}

.burn-success .modal-header .close,
.spinner-burn .modal-header .close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999;
}

.burn-success .group-info img {
    width: 180px;
    height: 240px;
    object-fit: contain;
}

.burn-success .group-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.burn-success .group-info .information {
    background: #fee7c8;
    padding: 1rem;
}

.burn-success .group-info .information p {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
}

.title-burn-success {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #fff900;
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    text-align: center;
    --stroke-color: #560202;
    --stroke-width: 4px;
    text-shadow: var(--stroke-width) 0 0 var(--stroke-color),
    calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
    0 var(--stroke-width) 0 var(--stroke-color),
    0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
    width: 243px;
}

.burn-success button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    font-size: 12px;
    padding: 0 !important;
}

.burn-success .modal-header .close {
    height: 30px;
    width: 30px;
    top: 7%;
}

.list-select-card .filter-card .left {
    padding: 0;
}

.list-select-card .filter-card .left .bg-game,
.list-select-card .filter-card .right {
    display: none;
}

.list-select-card .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: scroll;
    padding: 10px 0;
    width: 100%;
    flex: 1;
}

.list-select-card .list .card-item-confirm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc((100% - 2rem) / 2);
    margin: 0.5rem;
    justify-content: space-between;
}

.list-select-card .list .group-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fee7c8;
    width: 100%;
}

.list-select-card .list .group-info img {
    width: 100%;
}

.list-select-card .list .group-info .information {
    transform: none;
    background: none;
    width: 100%;
    padding: 1rem;
}

/* width */
.list-select-card .list::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.list-select-card .list::-webkit-scrollbar-track {
    background: #eea160;
}

/* Handle */
.list-select-card .list::-webkit-scrollbar-thumb {
    background: #d05334;
}

/* Handle on hover */
.list-select-card .list::-webkit-scrollbar-thumb:hover {
    background: #eea160;
}

.list-select-card .list .group-info {
    margin-bottom: 10px;
}

.confirm-btn {
    text-align: center;
    margin: 15px 0;
}

.list-select-card .modal-body {
    padding-top: 0;
}

.errorMsg {
    color: red;
}

.loading-burn-hero .modal-content,
.loading-burn .modal-content {
    min-height: 607px;
    border: none;
}

.loading-burn-hero .modal-body,
.loading-burn .modal-body {
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
    border: none;
}

.loading-burn .modal-body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url(../../../assets/img/fire.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 200px;
    animation: loadingburn 2s linear infinite;
}

.loading-burn .modal-body::after {
    content: '';
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
    background-image: url(../../../assets/img/fire.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 200px;
    animation: loadingburn2 2s linear infinite;
}

.loading-burn-hero .modal-body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url(../../../assets/img/background/bg-burn-hero.png);
    /* background-repeat: no-repeat; */
    width: 200%;
    height: 200px;
    background-size: auto 100%;
    animation: loadingburnhero 2s linear infinite;
}

.loading-burn-hero .modal-body::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-image: url(../../../assets/img/background/bg-burn-hero.png);
    background-size: auto 100%;
    width: 200%;
    height: 200px;
    animation: loadingburnhero2 2s linear infinite;
}

.loading-burn-hero img,
.loading-burn img {
    width: 50%;
}

.rename-input::placeholder {
    color: #989898;
    opacity: 1; /* Firefox */
    font-weight: normal;
}

.rename-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #989898;
    font-weight: normal;
}

.rename-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #989898;
    font-weight: normal;
}

.rename-input {
    padding-left: 10px;
}

@keyframes loadingburn {
    0% {
        top: 0;
    }
    100% {
        top: 100%;
    }
}

@keyframes loadingburn2 {
    0% {
        top: 100px;
    }
    100% {
        top: 100%;
    }
}

@keyframes loadingburnhero {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -10000px 0;
    }
}

@keyframes loadingburnhero2 {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -10000px 0;
    }
}

.card-item-confirm .group-info {
    cursor: pointer;
}

.card-item-confirm .group-info:active,
.card-item-confirm .group-info:visited,
.card-item-confirm .group-info:checked,
.card-item-confirm .group-info:focus,
.card-item-confirm .group-info.active {
    outline: 4px solid red;
}

.spinner-burn,
.burn-success {
    /* top: 25% !important; */
    /* transform: translateY(-5%) !important; */
}

.slick-list {
    padding-top: 60px;
    padding-bottom: 60px;
}

.slick-slide {
    transition: 0.2s;
}

.slick-slide:hover,
.slick-slide.active {
    transform: scale(1.3);
}

@media (min-width: 768px) {
    .burn-success .group-info .information {
        height: 53%;
        padding: 1rem 1rem 0 1em;
    }

    .show-confirm-card.burn-success .group-info .information {
        padding: 1rem;
    }

    .burn-success .group-info .information {
        height: auto;
        padding: 10px;
    }

    .title-burn-success {
        font-size: 26px;
        top: 33px;
        width: 445px;
    }

    .burn-success button {
        bottom: 5%;
        width: 154px;
        font-size: 16px;
        padding: 0 24px;
    }

    .burn-success .group-info .information p {
        font-size: 20px;
    }

    .list-select-card .group-info .information p {
        font-size: 16px;
    }

    .show-confirm-card .burn-success .modal-header {
        min-height: 600px;
    }

    .burn-success .modal-header img {
        height: 100%;
    }

    .list-select-card .modal-body {
        padding: 2rem !important;
    }
}

@media (min-width: 1024px) {
    .burn p {
        font-size: 20px;
    }

    .burn-success .modal-body .pending p,
    .spinner-burn .modal-body .pending p {
        font-size: 30px;
    }

    .burn-success .modal-body .pending span,
    .spinner-burn .modal-body .pending span {
        font-size: 20px;
    }

    .burn-success .group-info .information p {
        font-size: 16px;
    }

    .list-select-card .group-info .information p {
        font-size: 20px !important;
    }
}

@media (min-width: 1200px) {
    #uncontrolled-tab-example-tabpane-card-library::before {
        top: 20%;
    }
    .list-select-card .modal-dialog {
        width: 1100px !important;
    }

    .carousels {
        width: 1100px;
        max-width: 1100px;
        margin: 0 auto;
    }

    .slick-arrow.slick-next {
        right: -50px;
    }

    .slick-arrow.slick-prev {
        left: -50px;
    }

    .list-select-card .list .group-info {
        flex-direction: row;
        padding: 1rem;
    }

    .list-select-card .list .group-info img {
        width: 180px;
        height: 240px;
        margin-right: 1rem;
        object-fit: contain;
    }

    .list-select-card .list .group-info .information {
        min-width: unset;
        padding: 0;
    }

    .show-confirm-card .title-burn-success {
        top: 25px;
    }
}

.list__equip {
    background: url('../../../assets/img/background/bg-equip.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 60px 0;
}

.list__equip h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #020c16;
    text-align: center;
}

.list__equip--item {
    overflow: auto;
    max-height: 500px;
}

.list__equip--item .item {
    text-align: center;
    margin: 0 .5rem 1rem .5rem;
}


.list__equip--item .item.disabled,
.list__equip--item .item.removing {
    opacity: .7;
}

.list__equip--item .item img {
    margin-bottom: 15px;
    width: 180px;
    height: 240px;
    object-fit: contain;
}


@media (min-width: 768px) {
    .list__equip .list__equip--item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .list__equip .list__equip--item .item {
        width: fit-content;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
}
.pool {
  background-repeat: no-repeat;
  background-image: url(../../../assets/img/background/bg-pool.jpg);
  background-size: 100%;
}
.top {
  padding: 60px 0 120px;
  background-repeat: no-repeat;
  background-size: contain;
}
.top p,
.top h1 {
  display: block;
  width: 100%;
  text-align: center;
}
.top h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  color: #f4f8f9;
  --stroke-color: #560202;
    --stroke-width: 4px;
    text-shadow: var(--stroke-width) 0 0 var(--stroke-color),calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),0 var(--stroke-width) 0 var(--stroke-color),0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
}
.top p {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #560202;
}
.top .row .col-12 {
  text-align: center;
}
.bottom {
  transform: translateY(-30px);
  background-repeat: no-repeat;
}
#footer .themed-container  {
  padding-top: 15px;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .list__equip,
  .card__box {
    width: 1100px;
    max-width: 1100px;
    margin: 30px auto;
  }
  .list__equip,
  .card__box {
    padding: 0 14px;
  }
}
@media (min-width: 1200px) {
}
@media screen and (max-width: 767px) and (min-width: 600px) {
}

.themed-container .footer-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 120px 0 60px;
  flex-wrap: wrap;
}
.themed-container .footer-subscribe {
  width: 100%;
  flex: unset;
  margin: 0;
  padding: 0;
  max-width: 100%;
}
.themed-container .footer-column {
  *text-align: center;
  *width: 15% !important;
  *margin-left: -20px !important;
}
.themed-container .footer-logo {
  width: 167px;
  flex: unset;
  margin: auto;
  max-width: 100%;
  
}

.footer-container .groups-social {
  margin-top: 15px;
}
.footer-container .groups-social a {
  width: 40px;
  height: 40px;
  display: inline-block;
}
.footer-container .groups-social a:last-child {
  margin-right: 0;
}
.footer-container .last-column {
  background: unset;
  height: auto;
  *width: 100%;
  background: unset;
  *height: auto;
  padding: 0;
  margin: 0;
  text-align: left;
}
.themed-container .footer-form input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #020c16;
  padding: 10px;
}
.themed-container .last-column h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #f4f8f9;
}

.top-section,
.battle,
.expandalliance,
.footer {
  *overflow: hidden;
  *height: auto !important;
}
.top-section h1,
.battle h1,
.expandalliance h1 {
  word-break: break-all;
}

.themed-container .footer-column {
  margin-bottom: 30px;
}
.themed-container.hero-section .hero-container {
  margin: 0;
  padding: 120px 0;
}
.brand {
  min-height: 825px;
  *padding-bottom: 120px;
  *background-size: cover !important;
  *margin-bottom: -50px;
  background-position-y: -120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -2px !important;
}
.themed-container .footer-form {
  width: auto;
  max-width: auto;
}
.themed-container .footer-subscribe h1 {
  font-size: 20px;
  width: 100%;
  line-height: 35px;
}
.themed-container .hero-text {
  text-align: center;
}
.footer-container .groups-social a {
  margin-right: 7px;
}
.brand-section .text-center img {
  margin: auto;
}
.expandalliance {
  min-height: 820px;
}
.banner-section {
  min-height: 621px;
  line-height: 621px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.banner-section .banner-container {
  padding: 0;
}
.card-section {
  min-height: 945px;
  border-bottom: 4px solid red;
}
.hero-section {
  min-height: 594px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 4px solid #000;
  border-bottom: 6px solid #000;
}
.battle {
  min-height: 812px;
  background-position-y: -2px !important;
}
@media (max-width: 1200px) {
  .footer-container .groups-social a {
    margin-right: 15px;
  }
  .themed-container .footer-column {
    text-align: center;
    width: 100% !important;
    margin-left: 0 !important;
  }
  .themed-container .footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 120px 0 60px;
    margin: 0;
    grid-column-gap: unset;
  }

  .themed-container .footer-form {
    flex-direction: row;
    width: 500px;
    max-width: 500px;
  }
  .themed-container .hero-card {
    max-width: 70%;
  }
  .themed-container .hero-text {
    max-width: 30%;
  }

  .themed-container .card-title h1.heading {
    width: 38%;
    left: 50%;
    transform: translateX(-50%);
  }
  .top-section {
    height: auto;
    background-size: cover;
  }
  .banner-section {
    min-height: 300px;
    height: 300px;
    line-height: unset;
  }
}
@media (max-width: 1024px) {
  .nav-bar {
    padding-left: 60px;
  }
  .nav-bar li {
    margin-right: 30px;
  }
  .nav-bar li:last-child {
    margin-right: 0;
  }
  .nav-bar .ml-4 {
    margin: 0 0 0 1.5rem !important;
  }
  .nav-bar .ml-auto {
    margin-left: auto !important;
  }
  .themed-container .hero-card-item {
    width: 197px;
    height: 251px;
    min-height: 251px;
  }
  .themed-container .hero-container {
    flex-direction: row;
  }
  .themed-container .column-last {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-size: 100% 100%;
    height: auto;
    padding: 30px;
  }
  .themed-container .footer-form input {
    height: 52px;
  }
  .themed-container .hero-card-item {
    min-height: 300px;
    height: 300px;
  }
  .themed-container .hero-container {
    flex-direction: column;
  }
  .themed-container .hero-card {
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
  }
  .themed-container .hero-text {
    width: 100%;
    max-width: 100%;
    padding-bottom: 30px;
    text-align: center;
  }
  .themed-container h1 {
    width: 100%;
    margin: 30px 0;
  }
  .themed-container .card-title h1.heading {
    width: 38%;
    top: 22%;
  }
}

@media (max-width: 768px) {
  .themed-container .card-title h1.heading {
    top: 22%;
    font-size: 36px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
  }
  .themed-container .card-images-item {
    width: 317px;
    height: 371px;
    background-size: 100% 100%;
  }
  .themed-container .card-images {
    flex-direction: row;
    padding-bottom: 60px;
  }
  .themed-container .brand-section .brand-button button {
    min-width: 177px;
  }
  .themed-container .card-title {
    top: -10px;
  }
}

@media (max-width: 600px) {
  .nav-bar .ml-auto {
    margin-left: 0 !important;
  }
  .nav-bar .ml-4 {
    margin: 10px 0 0 0 !important;
  }
  .nav-bar .ml-4 .btn-blue {
    font-size: 14px;
  }
  .top-banner h1.heading {
    text-align: center;
  }
  .themed-container {
    /* height: auto !important; */
    /* padding: 30px 0 120px; */
  }
  .themed-container .card-title {
    background-size: contain;
    top: 60px;
  }
  .themed-container .card-title h1.heading {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 24px;
  }
  .themed-container .card-images {
    flex-direction: column;
  }

  .themed-container .card-images-item {
    width: 100%;
    background-position: center;
    background-size: 100% 100%;
  }
  .themed-container .card-images-item-link {
    bottom: 9%;
    left: 0;
    width: 100%;
    text-align: center;
    padding-left: 20px;
  }
  .themed-container .hero-container {
    flex-direction: column;
  }

  /* .themed-container .footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 120px 0 60px;
    margin: 0;
  } */
  .themed-container .footer-column {
    margin-bottom: 15px;
  }

  /* .themed-container .footer-logo {
    width: 100%;
    flex: unset;
    margin: 0;
    max-width: 100%;
  } */
  /* .themed-container h1 {
    width: 100%;
    margin: 30px 0;
  } */
  .themed-container .footer-form {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
  .themed-container .footer-subscribe input {
    height: auto;
    margin-bottom: 15px;
    width: 100%;
  }
  .themed-container .brand-section {
    background-size: 100% 100%;
    height: auto;
    width: 100%;
    padding-bottom: 31px;
  }
  .themed-container .brand-section .brand-button button {
    min-width: 145px;
  }
}

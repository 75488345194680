@font-face {
    font-display: block;
    font-family: 'Endless Boss Battle';
    src: local('Lato'),
    url(./assets/fonts/Endless-Boss-Battle.ttf) format('truetype');
}

@font-face {
    font-display: block;
    font-family: 'Minecraft';
    src: local('Lato'), url(./assets/fonts/Minecraft.ttf) format('truetype');
}

@font-face {
    font-display: block;
    font-family: 'Dinkie';
    src: local('Lato'), url(./assets/fonts/DinkieBitmap-9pxDemo.woff2) format('woff2');
}

body {
    background: #fff8ee;
    margin: 0;
    font-family: 'Minecraft', 'Dinkie', 'Endless Boss Battle', serif !important;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-xl {
    max-width: 1920px !important;
}

.container-lg {
    max-width: 1366px !important;
}

a:hover {
    text-decoration: none !important;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.card-shop {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
}

.fade.modal {
    display: flex !important;
}

.modal-dialog {
    width: 600px !important;
    margin: auto !important;
    max-width: 100% !important;
}

.spinner-buy .modal-body {
    border: 0;
    background: unset;
}

.spinner-buy .modal-dialog {
    width: 650px !important;
    max-width: 100% !important;
}

.spinner-buy .modal-body {
    padding: 0;
    border-top: 5px solid #fbe7cc;
    border-left: 5px solid #fbe7cc;
    border-bottom: 5px solid red;
    border-right: 5px solid red;
}

.spinner-buy .modal-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
}

.spinner-buy .modal-content {
    background-color: transparent;
}

.spinner-buy .modal-header .close {
    z-index: 55;
    right: 0;
}

.spinner-buy .pending {
    width: 100% !important;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background-color: #f7cb8c;
}

.spinner-buy.burning .pending {
    animation-name: none;
    justify-content: flex-end;
}

.spinner-buy.burning .group {
    height: 620px;
    overflow: hidden;
}

.spinner-buy.burning .group-fire {
    width: 100%;
    animation-name: example4;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    position: relative;
    animation-direction: alternate;
}

.spinner-buy.burning .group-fire figure {
    width: 100%;
    height: 320px;
    margin-bottom: 0;
}

.spinner-buy.burning .group-fire figure img {
    width: 100%;
    height: 100%;
    min-height: auto;
    object-fit: cover;
}

.spinner-buy.burning .group-fire figure.move-down {
    position: relative;
    top: 66px;
    z-index: 1;
}

.spinner-buy.burning .group-fire figure.move-up {
    position: relative;
    z-index: 2;
}

.spinner-buy .pending::before {
    content: '';
    top: -10px;
    left: 49.5%;
    transform: translateX(-50%);
    background: #000;
    height: 101.8%;
    width: 101.4%;
    z-index: -22;
    position: absolute;
}

.spinner-buy .pending::after {
    content: '';
    top: 0;
    left: 50.5%;
    transform: translateX(-50%);
    background: #000;
    height: 101.8%;
    width: 101.4%;
    z-index: -22;
    position: absolute;
}

.spinner-buy .pending .wrap {
    width: 332px;
    height: 444px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.spinner-buy .pending .wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spinner-buy .pending .wrap img.buy-only {
    animation-name: example2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.spinner-buy .white-bar {
    width: 100%;
    height: 190px;
    /* background-color: #fff; */
    background-image: url('./assets/img/white.png');
    background-size: contain;
    position: relative;
    z-index: 2;
}

@keyframes example2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes example4 {
    from {
        top: 0;
    }
    to {
        top: 400px;
    }
}

.wrap-library {
    background-color: #c25a3e;
    margin-top: 300px;
}

.wrap-library .inner {
    position: relative;
    bottom: 200px;
}

.wrap-library .white-bar-stripe {
    background-image: url('./assets/img/background/stripe.png');
    height: 40px;
    width: 100%;
    background-size: contain;
    position: absolute;
}

.wrap-library .white-bar-stripe.up {
    top: 250px;
}

.wrap-library .white-bar-stripe.down {
    bottom: -150px;
}
